<template>
    <!-- Projects grid -->
    <section class="pt-10 sm:pt-14">
        <!-- Projects grid title -->
        <div class="text-center">
            <p
                class="font-general-semibold text-2xl sm:text-5xl font-semibold mb-2 text-ternary-dark dark:text-ternary-light"
            >
                {{ projectsHeading }}
            </p>
        </div>

        <!-- Filter and search projects -->
        <div class="mt-10 sm:mt-10">
            <h3
                class="font-general-regular
					text-center text-secondary-dark
					dark:text-ternary-light
					text-md
					sm:text-xl
					font-normal
					mb-4
				"
            >
                Search projects by title or filter by category
            </h3>
            <div
                class="
					flex
					justify-between
					border-b border-primary-light
					dark:border-secondary-dark
					pb-3
					gap-4
				"
            >
                <div class="text-gray-600 w-full">

                    <div class="relative">
                        <span
                            class="absolute left-0 top-0 mt-3 ml-2 flex items-center pointer-events-none
							hidden
							sm:block"
                        >
						<svg xmlns="http://www.w3.org/2000/svg" version="1.1" id="Capa_1" x="0px" y="0px"
                             viewBox="0 0 56.966 56.966" xml:space="preserve" width="512px" height="512px"
                             class="text-gray-200 h-4 w-4 fill-current">
                            <path d="M55.146,51.887L41.588,37.786c3.486-4.144,5.396-9.358,5.396-14.786c0-12.682-10.318-23-23-23s-23,10.318-23,23 s10.318,23,23,23c4.761,0,9.298-1.436,13.177-4.162l13.661,14.208c0.571,0.593,1.339,0.92,2.162,0.92 c0.779,0,1.518-0.297,2.079-0.837C56.255,54.982,56.293,53.08,55.146,51.887z M23.984,6c9.374,0,17,7.626,17,17s-7.626,17-17,17 s-17-7.626-17-17S14.61,6,23.984,6z"></path>
                        </svg>
					</span>
                        <input
                            v-model="searchProject"
                            class="font-general-medium
                        w-full border border-gray-300 bg-white h-10 md:pl-8 focus:outline-secondary-light
						dark:border-secondary-dark
						rounded-lg
						text-sm
						sm:text-md
						bg-secondary-light
						dark:bg-ternary-dark
						text-primary-dark
						dark:text-ternary-light
						"
                            id="name"
                            name="name"
                            type="search"
                            required=""
                            placeholder="Search Projects"
                            aria-label="Name"
                        />
                    </div>
                </div>
                <ProjectsFilter @filter="selectedCategory = $event"/>
            </div>
        </div>

        <!-- Projects grid -->
        <div
            class="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 mt-6 sm:gap-10"
        >
            <ProjectSingle
                v-for="project in filteredProjects"
                :key="project.id"
                :project="project"
            />
        </div>
    </section>
</template>
<script type="text/javascript">
import feather from 'feather-icons';
import ProjectsFilter from './ProjectsFilter.vue';
import ProjectSingle from './ProjectSingle.vue';
import projects from '../../data/projects.js';

export default {
    components: {
        ProjectSingle,
        ProjectsFilter
    },
    data: () => {
        return {
            projects,
            projectsHeading: 'Projects i worked on/Contributed to',
            selectedCategory: '',
            searchProject: '',
        };
    },
    computed: {
        // Get the filtered projects
        filteredProjects() {
            if (this.selectedCategory) {
                return this.filterProjectsByCategory();
            } else if (this.searchProject) {
                return this.filterProjectsBySearch();
            }
            return this.projects;
        },
    },
    methods: {
        // Filter projects by category
        filterProjectsByCategory() {
            return this.projects.filter((item) => {
                let category =
                    item.category.charAt(0).toUpperCase() +
                    item.category.slice(1);
                return category.includes(this.selectedCategory);
            });
        },
        // Filter projects by title search
        filterProjectsBySearch() {
            let project = new RegExp(this.searchProject, 'i');
            return this.projects.filter((el) => el.title.match(project));
        },
    },
    mounted() {
        feather.replace();
    },
};
</script>

<style scoped></style>

<template>
    <div
        class="relative project rounded-xl shadow-lg hover:shadow-xl cursor-pointer mb-10 sm:mb-0 bg-secondary-light dark:bg-ternary-dark"
        aria-label="Single Project"
    >
        <div>
            <img
                :src="project.img"
                :alt="project.title"
                class="rounded-t-xl border-none"
            />
        </div>
        <div class="text-center px-4 py-6">
            <p
                class="font-general-semibold text-xl text-ternary-dark dark:text-ternary-light font-semibold mb-2"
            >
                {{ project.title }}
            </p>
            <span
                class="font-general-medium text-lg text-ternary-dark dark:text-ternary-light"
            >{{ project.category }}</span
            >
        </div>
        <div class="description absolute top-0 left-0 text-center text-white rounded-xl leading-8 px-4 py-10 text-xl"
             v-if="project.description">
            {{ project.description }}
        </div>
    </div>
</template>
<script>
export default {
    props: ['project'],
};
</script>

<style lang="scss" scoped>
.project {

    .description {
        box-sizing: border-box;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.7);
        visibility: hidden;
        opacity: 0;
        transition: opacity 0.3s;
    }

    &:hover .description {
        visibility: visible;
        opacity: 1;
    }
}
</style>

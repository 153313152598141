<template>

    <!-- NavBar -->
    <nav
        class="container px-6 mx-auto md:flex md:justify-between md:items-center"
    >

            <!-- Mobile menu button -->
            <div @click="showMenu = !showMenu" class="flex md:hidden">
                <button
                    type="button"
                    class="text-gray-800 hover:text-gray-400 focus:outline-none focus:text-gray-400"
                >
                    <svg viewBox="0 0 24 24" class="w-6 h-6 fill-current">
                        <path
                            fill-rule="evenodd"
                            d="M4 5h16a1 1 0 0 1 0 2H4a1 1 0 1 1 0-2zm0 6h16a1 1 0 0 1 0 2H4a1 1 0 0 1 0-2zm0 6h16a1 1 0 0 1 0 2H4a1 1 0 0 1 0-2z"
                        ></path>
                    </svg>
                </button>
            </div>
        <!-- Mobile Menu open: "block", Menu closed: "hidden" -->
        <ul
            :class="showMenu ? 'flex' : 'hidden'"
            class="flex-col mt-8 space-y-4 md:flex md:space-y-0 md:flex-row justify-center items-center md:space-x-10 md:mt-0"
        >
            <li class="menu-button">
                <a href="#">Home</a>
            </li>
            <li class="menu-button">
                <a href="#work" v-smooth-scroll>
                    Works
                </a>
            </li>
            <li class="menu-button">
                <a href="#me" v-smooth-scroll>
                    Me
                </a>
            </li>
        </ul>
    </nav>
    <!-- End Navbar -->
</template>
<script type="text/javascript">
export default {
    data: () => {
        return {
            theme: '',
            showMenu: false,
        }
    },
}
</script>
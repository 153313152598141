<template>

    <div class="banner-1 flex max-h-full items-center" id="contact">
        <div class="w-7/12 md:p-12 p-3">
            <h2 class="text-gray-700 md:text-4xl text-lg font-Eczar mb-5 font-bold">
                Let’s work together and make everything super cute and super useful.
            </h2>
            <a class="underline md:text-2xl text-xl text-blue-600 font-work_sans"
            >📧 me@chistel.com</a
            >
        </div>
        <div class="w-5/12 pr-12">
            <div class="flex flex-col justify-center items-center mb-12 sm:mb-20">
                <p
                    class="font-general-semibold text-xl sm:text-4xl font-semibold text-primary-dark dark:text-primary-light mb-5"
                >
                    Follow me
                </p>
                <ul class="flex gap-4 sm:gap-8">
                    <a
                        v-for="social in socials"
                        :key="social.id"
                        :href="social.url"
                        target="__blank"
                        class="text-gray-400 hover:text-indigo-500 dark:hover:text-indigo-400 cursor-pointer rounded-full bg-gray-50 dark:bg-ternary-dark hover:bg-gray-100 shadow-sm md:p-4 p-2 duration-500"
                    >
                        <i
                            :data-feather="social.icon"
                            class="w-3 sm:w-6 h-3 sm:h-6"
                        ></i>
                    </a>
                </ul>
            </div>
        </div>
    </div>
</template>

<script type="text/javascript">

import feather from 'feather-icons';
export default {
    data() {
        return {
            socials: [
                {
                    id: 1,
                    name: 'GitHub',
                    icon: 'github',
                    url: 'https://github.com/chistel',
                },
                {
                    id: 2,
                    name: 'Twitter',
                    icon: 'twitter',
                    url: 'https://twitter.com/chistel-brown',
                },
                /*{
                    id: 3,
                    name: 'Medium',
                    icon: 'book',
                    url: 'https://stoman.medium.com',
                },
                {
                    id: 4,
                    name: 'Instagram',
                    icon: 'instagram',
                    url: 'https://instagram.com/realstoman',
                },*/
                {
                    id: 5,
                    name: 'LinkedIn',
                    icon: 'linkedin',
                    url: 'https://ng.linkedin.com/in/chistel-brown',
                }
            ],
        };
    },
    mounted() {
        feather.replace();
    },
    updated() {
        feather.replace();
    },
}
</script>
// Projects data.
// Note: Here we are getting data from a js file, but in a different project I'll be fetching these projects from some srt of APIs.

const projects = [
	{
		id: 2,
		title: 'Carthook',
		category: 'Shopify App',
		img: require('@/assets/images/projects/carthook.png'),
		description: "Carthook is a post purchase app for shopify build by me and a few other devs at Pantastic"
	},
	{
		id: 2,
		title: 'Hailatutor',
		category: 'Edu Tech',
		img: require('@/assets/images/projects/hailatutor.png'),
		description: "HailATutor is a peer to peer replica of codementor for higher institution which is built for a company called GatesAcademy. it's build with Laravel/Mysql/Vuejs/Redis and Websocket, with support of external api services"
	},
	{
		id: 3,
		title: 'Awardforce',
		category: 'Web Application',
		img: require('@/assets/images/projects/awardforce.png'),
	},
	{
		id: 4,
		title: 'Prodevs',
		category: 'Recruitment',
		img: require('@/assets/images/projects/prodevs2.png'),
	},
	{
		id: 5,
		title: 'Medbury Emr',
		category: 'Health Care',
		img: require('@/assets/images/projects/emr.png'),
		description: "Medbury Emr is ER platform for managing patients medical records, build for Medbury medical in Lagos Nigeria. it's build with Laravel/Vue-js/Beanstack/Mysql"
	},
	{
		id: 4,
		title: 'OracleFilms',
		category: 'Entertainment',
		img: require('@/assets/images/projects/oraclefims-two.png'),
		description: "Oracle films is a movie streaming platform for local content, built with php/laravel, Vue 3, redis, php ffmpeg, mysql, livewire, alpine js"
	},
	{
		id: 6,
		title: 'Expert markets',
		category: 'Web Application',
		img: require('@/assets/images/projects/expert-market.png'),
	},
	{
		id: 7,
		title: 'ThatApp',
		category: 'Web Application',
		img: require('@/assets/images/projects/thatapp.png'),
		description: "ThatApp is an automation, and backup platform owned by Techego LLC(USA) for external service like podio, shopify. it's build with Laravel/React/Nodejs/Mysql/MongoDB, with support of external api services"
	},
	{
		id: 9,
		title: 'Medbury Covid ',
		category: 'Health Care',
		img: require('@/assets/images/projects/covid-medbury.png'),
		description: "Carthook is a post purchase app for shopify build by me and a few other devs at Pantastic"
	},
];

export default projects;

<template xmlns="http://www.w3.org/1999/html">
    <div
        class="me-color px-5 3xl:px-36 2xl:px-36 xl:px-28 lg:px-24 md:px-16 md:flex items-center md:justify-evenly"
        id="me">
        <div class="py-8 px-8 md:py-10 md:w-1/2">
            <h4 class="text-gray-700 text-3xl md:text-4xl font-work_sans tracking-wide font-bold item mb-2">
                What i do ??
            </h4>
            <p class="font-work_sans text-xl whitespace-pre-line break-words">
                I build with (<b>Php/Laravel/Javascript/Vue/Tailwind/Nodejs</b>) and I also learn.
                When other devs needs my help, I try to make myself available
                <br/>
                Hoping to join the space of technical writing. 😉
            </p>
        </div>
    </div>

</template>
<style>
.me-color {
    background-color: #ffddbf;
}
</style>
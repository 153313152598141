<template>

    <div :class="appTheme">

        <div class="px-5 pt-5 md:pt-10 3xl:px-36 2xl:px-36 xl:px-28 lg:px-24 md:px-16">
            <app-header></app-header>
            <div class="flex items-center">
                <div class="w-11/12 space-y-5 py-5 md:space-y-5">
                    <h3
                        class="hello-button text-gray-700 w-fit md:py-3 md:px-5 py-1 px-2 rounded-lg 3xl:text-3xl 2xl:text-xl lg:text-xl md:text-md text-sm font-semibold font-work_sans"
                    >
                        👋 Good Day,
                    </h3>
                    <h1
                        class="3xl:text-8xl 2xl:text-7xl xl:text-6xl lg:text-4xl md:text-3xl sm:text-3xl text-xl font-Eczar font-bold leading-tight text-gray-700"
                    >
                        It's
                        <a
                            href="https://www.linkedin.com/in/chistel-brown"
                            target="_blank"
                            class="text-black hover:underline"
                        >
                            Chistel
                        </a>
                        🙇‍♀️
                        <br/>A full-stack software developer
                    </h1>

                    <div class="md:flex gap-1 md:gap-10 ">
                        <a class="text-white bg-gray-800 submit-button hidden md:block" href="#work">
                            View My Work
                        </a>
                        <a href="#contact"
                            class="text-gray-800 border-4 border-gray-800 submit-button mb-10 md:mb-0"
                        >
                            Hire Me
                        </a>
                    </div>
                </div>

                <div class="w-8/12 flex justify-end">
                    <img
                        class="3xl:h-[800px] 2xl:h-[450px] xl:h-[500px] lg:h-[500px] md:h-[450px] sm:h-[350px]"
                        src="./assets/images/developer.svg"
                        alt="user image"
                    />
                </div>
            </div>
        </div>
        <me></me>
        <div class="container mx-auto py-4" id="work">
            <ProjectsGrid></ProjectsGrid>
        </div>
        <!-- Scroll to top -->
        <back-to-top
            visibleoffset="500"
            right="30px"
            bottom="20px"
            class="shadow-lg"
        >
            <i data-feather="chevron-up"></i>
        </back-to-top>

        <app-footer></app-footer>
        <!-- Hire me modal -->
        <HireMeModal
            :showModal="showModal"
            :modal="modal"
            aria-modal="Hire Me Modal"
        />
    </div>
</template>
<script type="text/javascript">
import feather from 'feather-icons';
import AppHeader from './components/shared/AppHeader';
import AppFooter from "./components/shared/AppFooter";
import Me from "./views/Me.vue";
import ProjectsGrid from "./components/projects/ProjectsGrid";
import HireMeModal from "./components/HireMeModal";
import Button from './components/reusable/Button.vue';
export default {
    components: {
        AppHeader,
        AppFooter,
        Me,
        ProjectsGrid,
        HireMeModal,
        Button
    },
    data: () => {
        return {
            modal: false,
            appTheme: localStorage.getItem('theme'),
        };
    },
    mounted() {
        feather.replace();
    },
    updated() {
        feather.replace();
    },
    methods: {
        showModal() {
            if (this.modal) {
                // Stop screen scrolling
                document
                    .getElementsByTagName('html')[0]
                    .classList.remove('overflow-y-hidden');
                this.modal = false;
            } else {
                document
                    .getElementsByTagName('html')[0]
                    .classList.add('overflow-y-hidden');
                this.modal = true;
            }
        },
    },
}
</script>


<style>
#app {
    font-family: Avenir, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
    margin: 0;
    padding: 0;
}

.vue-back-to-top {
    @apply p-2 transition
    duration-500
    ease-in-out
    transform
    hover:-translate-y-1 hover:scale-110;
    border-radius: 50%;
    font-size: 22px;
    line-height: 22px;
    background-color: #fff;
    border: white;
    color: #ffddbf;
    font-weight: bold;
}

.fade-enter-active {
    animation: coming 0.4s;
    animation-delay: 0.2s;
    opacity: 0;
}

.fade-leave-active {
    animation: going 0.4s;
}

@keyframes going {
    from {
        transform: translateX(0);
    }
    to {
        transform: translateX(-10px);
        opacity: 0;
    }
}

@keyframes coming {
    from {
        transform: translateX(-10px);
        opacity: 0;
    }
    to {
        transform: translateX(0px);
        opacity: 1;
    }
}
</style>


